import {cleanupTaskName, env} from './util';

const domain = env === 'local' ? 'int-petdesk.com' : env === 'prod' ? 'petdesk.com' : `${env}-petdesk.com`;

class Api {
  listTask = async () => {
    const tasks = await this.get('/tasks');
    return tasks.map(task => ({...task, ...{
      shortName: cleanupTaskName(task.name)
    }}));
  }

  listUpcomingInvocations = async () => this.get('/upcoming_invocations');

  enableTask = async taskName => this.post(`/tasks/${taskName}/enable`);

  disableTask = async taskName => this.post(`/tasks/${taskName}/disable`);

  get = async path => {
    const response = await fetch(`https://bentley-api.internal.${domain}:451${path}`);
    return response.json();
  };

  post = async (path, data) => {
    const response = await fetch(`https://bentley-api.internal.${domain}:451${path}`, {
      method: 'POST',
      body: JSON.stringify(data)
    });
    if (response.status !== 204) {
      return response.json();
    }
  };
}


export default new Api();

import React, {useContext, useEffect, useState} from 'react';
import {
  Box, CircularProgress,
  Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Layout from './Layout';
import api from './api';
import {VPNDialogContext} from './contexts';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(theme => {
  return {
    tableHeader: {
      backgroundColor: theme.palette.grey.A700,
    },
    tableCellHead: {
      color: theme.palette.primary.contrastText
    },
    noWrapCell: {
      whiteSpace: 'nowrap'
    },
  };
});

function UpcomingInvocations() {
  const [rows, setRows] = useState(null);
  const [filteredRows, setFilteredRows] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const openVPNDialog = useContext(VPNDialogContext);
  const classes = useStyles();

  useEffect(() => {
    api.listTask().then(setRows).catch(err => openVPNDialog(true));
  }, [openVPNDialog]);

  useEffect(() => {
    if (rows === null) {
      return;
    }
    if (searchValue.trim() === '') {
      setFilteredRows([...rows]);
      return;
    }
    const segments = searchValue.trim().split(/\s+/);
    setFilteredRows(rows.filter(row => {
      const r = JSON.stringify(row);
      for (let segment of segments) {
        if (!r.includes(segment)) {
          return false;
        }
      }
      return true;
    }));
  }, [rows, searchValue]);

  const toggleTaskState = event => {
    let promise;
    if (event.target.checked) {
      promise = api.enableTask(event.target.name);
    } else {
      if (! window.confirm(`Do you really want to disable the task ${event.target.name}?\n\n This task will be automatically re-enabled at the next Bentley deployment.`)) {
        return;
      }
      promise = api.disableTask(event.target.name);
    }
    promise.then(() => {
      api.listTask().then(setRows);
    });
  };

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
  }

  return (
    <Layout tabValue={0}>
      <Container maxWidth="xl">
        {filteredRows === null &&
        <Box mt={8} style={{textAlign: 'center'}}>
          <CircularProgress color="primary"/>
        </Box>
        }
        {filteredRows !== null &&
        <>
          <Box mb={2} style={{textAlign: 'right'}}>
            <FormControl className={classes.margin}>
              <InputLabel htmlFor="input-with-icon-adornment">Filter tasks</InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={searchValue}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableCellHead}>Task name</TableCell>
                  <TableCell className={classes.tableCellHead}>Task command(s)</TableCell>
                  <TableCell className={classes.tableCellHead}>Schedule</TableCell>
                  <TableCell className={classes.tableCellHead}>Target instance size</TableCell>
                  <TableCell className={classes.tableCellHead}>Enabled</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell>
                      {row.shortName}
                    </TableCell>
                    <TableCell className={classes.noWrapCell}>
                      {row.commands.map(cmd => (
                        <React.Fragment key={row.name + cmd}><code>{cmd}</code><br /></React.Fragment>
                      ))}
                    </TableCell>
                    <TableCell className={classes.noWrapCell}>
                      <code>{row.cron}</code>
                    </TableCell>
                    <TableCell className={classes.noWrapCell}>
                      <code>{row.target}</code>
                    </TableCell>
                    <TableCell>
                      <Switch
                        color="primary"
                        checked={row.enabled}
                        onChange={toggleTaskState}
                        name={row.name}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        }
      </Container>
    </Layout>
  );
}

export default UpcomingInvocations;

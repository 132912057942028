export const getHumanReadableTimeDiff = (date1, date2) => {
  let diffInHours = 0;
  let diffInMinutes = 0;
  let diffInSeconds = Math.round(((new Date(date2)).getTime() - (new Date(date1)).getTime()) / 1000);

  if (diffInSeconds >= 60) {
    diffInMinutes = Math.floor(diffInSeconds / 60);
    diffInSeconds -= 60 * diffInMinutes;
  }

  if (diffInMinutes >= 60) {
    diffInHours  = Math.floor(diffInMinutes / 60);
    diffInMinutes -= 60 * diffInHours;
  }

  if (diffInHours > 0) {
    return `${diffInHours}h ${diffInMinutes}m ${diffInSeconds}s`;
  }

  if (diffInMinutes > 0) {
    return `${diffInMinutes}m ${diffInSeconds}s`;
  }

  return `${diffInSeconds}s`;
}

const location = window.location;
let envName;
if (location.hostname.match(/\.petdesk\.com/)) {
  envName = 'prod';
} else {
  const matches = location.hostname.match(/\.((.+)-petdesk)\.com/);
  envName = matches ? matches[2] : 'int';
}

export const env = envName;

export const cleanupTaskName = name => name.replace(/^[^-]+-bentley-/, '').replace(/-cmd$/, '')
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {env} from './util';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => {
  return {
    appBar: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
    },
    toolbar: {
      flexGrow: 1,
    },
    highlighted: {
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.secondary.main,
    }
  };
});

const Layout = props => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography variant="h6">
            Bentley Dashboard - {env} environment
          </Typography>
        </Toolbar>
        <Tabs value={props.tabValue}>
          <Tab label="Tasks" component={Link} to="/" />
          <Tab label="Upcoming invocations" component={Link} to="/upcoming" />
        </Tabs>
      </AppBar>
      {props.children}
    </>
  );
}

export default Layout;

import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import UpcomingInvocations from './UpcomingInvocations';
import Tasks from './Tasks';
import NotFound from './NotFound';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import {VPNDialogContext} from './contexts';

function App() {
  const [VPNDialogOpened, openVPNDialog] = useState(false);

  return (
    <VPNDialogContext.Provider value={openVPNDialog}>
      <Router>
        <Switch>
          <Route exact path="/" component={Tasks} />
          <Route exact path="/upcoming" component={UpcomingInvocations} />
          <Route path="/" component={NotFound} />
        </Switch>
      </Router>
      <Dialog
        open={VPNDialogOpened}
        onClose={() => {}}
      >
        <DialogTitle id="alert-dialog-title">Could not fetch data from the backend</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Make sure you turned the VPN on, and refresh this page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.reload()} color="primary">
            OK, VPN is On
          </Button>
        </DialogActions>
      </Dialog>
    </VPNDialogContext.Provider>
  );
}

export default App;

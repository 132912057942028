import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {cleanupTaskName, getHumanReadableTimeDiff} from './util';
import api from './api';
import Layout from './Layout';
import {VPNDialogContext} from './contexts';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => {
  return {
    tableHeader: {
      backgroundColor: theme.palette.grey.A700,
    },
    tableCellHead: {
      color: theme.palette.primary.contrastText
    },
    noWrapCell: {
      whiteSpace: 'nowrap'
    },
    fadedRow: {
      '&> td': {
        color: '#ccc',
      }
    }
  };
});

function UpcomingInvocations() {
  const [rows, setRows] = useState(null);
  const [filteredRows, setFilteredRows] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [upcomingInvocations, setUpcomingInvocations] = useState(null);
  const openVPNDialog = useContext(VPNDialogContext);
  const classes = useStyles();

  useEffect(() => {
    api.listUpcomingInvocations().then(setUpcomingInvocations).catch(err => openVPNDialog(true));
  }, [openVPNDialog]);

  useEffect(() => {
    if (upcomingInvocations === null) {
      return;
    }
    const timer = setInterval(() => {
      setRows(upcomingInvocations.map(t => ({
        ...t, ...{
          taskName: cleanupTaskName(t.taskName),
          time: (new Date(t.date)).toLocaleTimeString(),
          countdownInSeconds: Math.round(((new Date(t.date)).getTime() - (new Date()).getTime()) / 1000),
          humanReadableCountdown: getHumanReadableTimeDiff((new Date()).getTime(), t.date)
        }
      })));
    });
    return function cleanup() {
      clearInterval(timer);
    };
  }, [upcomingInvocations]);

  useEffect(() => {
    if (rows === null) {
      return;
    }
    if (searchValue.trim() === '') {
      setFilteredRows([...rows]);
      return;
    }
    const segments = searchValue.trim().split(/\s+/);
    setFilteredRows(rows.filter(row => {
      const r = JSON.stringify(row);
      for (let segment of segments) {
        if (!r.includes(segment)) {
          return false;
        }
      }
      return true;
    }));
  }, [rows, searchValue]);

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
  }

  return (
    <Layout tabValue={1}>
      <Container maxWidth="xl">
        {filteredRows === null &&
        <Box mt={8} style={{textAlign: 'center'}}>
          <CircularProgress color="primary"/>
        </Box>
        }

        {filteredRows !== null &&
        <>
          <Box mb={2} style={{textAlign: 'right'}}>
            <FormControl className={classes.margin}>
              <InputLabel htmlFor="input-with-icon-adornment">Filter tasks</InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={searchValue}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableCellHead}>Task name</TableCell>
                  <TableCell className={classes.tableCellHead}>Task command(s)</TableCell>
                  <TableCell className={classes.tableCellHead}>Target instance size</TableCell>
                  <TableCell className={classes.tableCellHead}>Next run (in your local timezone)</TableCell>
                  <TableCell className={classes.tableCellHead}>Countdown</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map(row => (
                  <TableRow key={row.taskName + row.date} className={row.countdownInSeconds <= 0 ? classes.fadedRow : ''}>
                    <TableCell>
                      {row.taskName}
                    </TableCell>
                    <TableCell className={classes.noWrapCell}>
                      {row.commands.map(cmd => (
                        <React.Fragment key={row.taskName + row.date + cmd}><code>{cmd}</code><br /></React.Fragment>
                      ))}
                    </TableCell>
                    <TableCell className={classes.noWrapCell}>
                      <code>{row.target}</code>
                    </TableCell>
                    <TableCell className={classes.noWrapCell}>
                      {row.time}
                    </TableCell>
                    <TableCell className={classes.noWrapCell}>
                      {row.countdownInSeconds <= 0 ? '' : row.humanReadableCountdown}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        }
      </Container>
    </Layout>
  );
}

export default UpcomingInvocations;

import React from 'react';
import {
  Container, Typography,
} from '@material-ui/core';
import Layout from './Layout';

function NotFound() {
  return (
    <Layout>
      <Container maxWidth="md">
        <Typography variant="h2" align="center" style={{marginTop: '150px'}}>
          Page not found :(
        </Typography>
      </Container>
    </Layout>
  );
}

export default NotFound;
